import { Link } from "remix";

export function Logo({
  className,
  width = "100%",
}: {
  className?: string;
  width?: string;
}) {
  return (
    <Link to="/" aria-label="JSON解析器" className="w-40">
      <svg
        className={className}
        width={width}
        height="50"
        viewBox="0 0 196 50"
        xmlns="http://www.w3.org/2000/svg"
      >
          <path id="JSONViewer" fill="#c740de" aria-label="JSONViewer"
                d="m13 36.4v-21h3.1v21q0 4.1-1.6 6-1.5 1.8-4.9 1.8h-1.2v-2.6h0.9q2.1 0 2.9-1.1 0.8-1.1 0.8-4.1zm22.7-20.3v3q-1.7-0.8-3.3-1.2-1.5-0.4-2.9-0.4-2.5 0-3.9 0.9-1.3 1-1.3 2.8 0 1.5 0.9 2.3 0.9 0.7 3.4 1.2l1.8 0.4q3.4 0.6 5.1 2.3 1.6 1.6 1.6 4.4 0 3.2-2.2 4.9-2.2 1.7-6.5 1.7-1.6 0-3.4-0.3-1.8-0.4-3.7-1.1v-3.1q1.8 1 3.6 1.5 1.8 0.6 3.5 0.6 2.7 0 4.1-1.1 1.4-1 1.4-2.9 0-1.7-1-2.6-1-1-3.4-1.4l-1.8-0.4q-3.5-0.7-5-2.1-1.5-1.5-1.5-4.1 0-3 2.1-4.7 2.1-1.7 5.8-1.7 1.6 0 3.3 0.3 1.6 0.3 3.3 0.8zm15.3 1.4q-3.3 0-5.3 2.5-1.9 2.4-1.9 6.7 0 4.3 1.9 6.8 2 2.5 5.3 2.5 3.4 0 5.3-2.5 2-2.5 2-6.8 0-4.3-2-6.7-1.9-2.5-5.3-2.5zm0-2.5q4.8 0 7.6 3.2 2.9 3.2 2.9 8.5 0 5.4-2.9 8.6-2.8 3.1-7.6 3.1-4.7 0-7.6-3.1-2.8-3.2-2.8-8.6 0-5.3 2.8-8.5 2.9-3.2 7.6-3.2zm15.3 23v-22.6h4.1l10 18.9v-18.9h3v22.6h-4.1l-10.1-18.9v18.9z"/>
          <path id="JSONViewer" fill="#eff0f0" aria-label="JSONViewer"
                d="m98.7 38h-3.4l-8.6-22.6h3.1l7.2 19 7.2-19h3.2zm11.1 0v-17h2.8v17zm0-20v-3.6h2.7v3.6zm23.2 10.8v1.4h-12.9q0.2 2.9 1.8 4.4 1.5 1.5 4.3 1.5 1.6 0 3.1-0.4 1.5-0.4 3-1.2v2.6q-1.5 0.7-3.1 1-1.5 0.3-3.2 0.3-4 0-6.4-2.3-2.4-2.4-2.4-6.4 0-4.2 2.3-6.6 2.2-2.5 6.1-2.5 3.4 0 5.4 2.2 2 2.2 2 6zm-12.8-0.8h10q-0.1-2.3-1.3-3.6-1.3-1.4-3.3-1.4-2.3 0-3.8 1.3-1.3 1.3-1.6 3.7zm20.1 10l-4.4-17h2.8l3.5 13.3 3.4-13.3h3.3l3.5 13.3 3.5-13.3h2.8l-4.5 17h-3.3l-3.6-13.9-3.7 13.9zm37.1-9.2v1.4h-12.8q0.1 2.9 1.7 4.4 1.5 1.5 4.3 1.5 1.6 0 3.1-0.4 1.5-0.4 3-1.2v2.6q-1.5 0.7-3.1 1-1.5 0.3-3.2 0.3-4 0-6.4-2.3-2.3-2.4-2.3-6.4 0-4.2 2.2-6.6 2.2-2.5 6.1-2.5 3.4 0 5.4 2.2 2 2.2 2 6zm-12.8-0.8h10q-0.1-2.3-1.3-3.6-1.2-1.4-3.3-1.4-2.3 0-3.7 1.3-1.4 1.3-1.7 3.7zm27.1-7.2l0.1 2.9q-0.5-0.3-1.1-0.4-0.5-0.2-1.2-0.2-2.3 0-3.6 1.6-1.3 1.5-1.3 4.4v8.9h-2.8v-17h2.8v2.7q0.9-1.6 2.3-2.3 1.4-0.8 3.4-0.8 0.3 0 0.7 0.1 0.3 0 0.7 0.1z"/>
      </svg>
    </Link>
  );
}
